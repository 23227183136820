<script>
import childNameOrChildren from '@/mixins/childNameOrChildren';
import partnerNames from '@/mixins/partnerNames';
import monthOptions from '@/lib/monthOptions';
import holidays from '@/lib/holidays';
import {hasEditPermission} from '@/mixins/roleLevelPermissions';
import { mapState } from 'vuex';

const holidayDecision = [
	{
		text: 'No change to parenting time schedule',
		value: 'no-change',
	},
	{
		text: 'Suspend parenting time and take a flexible approach',
		value: 'suspend',
	},
	{
		text: 'Specify a change',
		value: 'specify-change',
	},
];

export default {
	mixins: [partnerNames, childNameOrChildren],
	props: ['agreementData'],
	data() {
		return { monthOptions, holidayDecision, additionalHolidaysInput:'' , showAddHoliday: false,
			isViewMode : !hasEditPermission('section-parenting') };
		
	},
	computed: {
		...mapState(['currentRoleLevel']),
		parenting() {
			return this.getParentingUpdated();
			//this.agreementData.parenting;
		},
		// holidayFields() {
		// 	const holidayMap = holidays(this.agreementData);
		// 	return holidayMap.filter(({ type }) => type === 'holiday');
		// },
		holidayAndSpecialDayFields() {
			const holidayMap = holidays(this.agreementData);
			return holidayMap.filter(({ type }) => type === 'holiday' || type === 'specialDays');
		},
		schoolBreakFields() {
			const holidayMap = holidays(this.agreementData);
			return holidayMap.filter(({ type }) => type === 'schoolBreak');
		},
		// specialDayFields() {
		// 	const holidayMap = holidays(this.agreementData);
		// 	return holidayMap.filter(({ type }) => type === 'specialDays');
		// },
		isUserSeparatingParty(){
			return this.currentRoleLevel.roleAssociated === 'party'
		},
		dynamicQuestions(){
			if(this.isUserSeparatingParty){
				return {
					arrangement: 'What is your Parenting Arrangement?',
					parentingTimeHolidayMeeting: 'Will you meet periodically to proactively discuss holiday plans?',
					parentingTimeHolidayMeetingMonth: "When will you meet to discuss holiday plans?",
					agreeChildcareProviders:"Do you want to agree on using the same childcare providers in each of your homes?",
					firstRightOfRefusal: `Do you want the right of first refusal to care for ${this.childNameOrChildren}?`,
					timeDriving:`Will each of you be responsible to drive ${this.childNameOrChildren} to activities during your parenting time?`,
					supervision:"Does either parent require supervision during parenting time?"
				}
			}
			else{
				return {
					arrangement: "What is the Parenting Arrangement?",
					parentingTimeHolidayMeeting: 'Will the parties be meeting periodically to discuss upcoming holiday plans?',
					parentingTimeHolidayMeetingMonth: "When will the parties meet to discuss upcoming holiday plans?",
					agreeChildcareProviders:"Do the parties wish to agree on the same childcare providers?",
					firstRightOfRefusal:"Do the parties want the right of first refusal for child care?",
					timeDriving:`Will both parties be responsible to drive ${this.childNameOrChildren} to activities during their parenting time?`,
					supervision:"Does either party require supervision during their time?"

				}
			}
		},
		whatToDoWithHolidaysAndSpecialDaysOptions(){
			if(this.isUserSeparatingParty){
				return [
					{
						value: 'follow-normal-schedule',
						text: 'We will follow our default Parenting Time Schedule',
					},
					{
						value: 'follow-flexible-schedule',
						text: 'We will take a flexible approach to holiday time generally',
					},
					{ value: 'follow-own-schedule', text: `We will attach a Holiday Schedule to the Agreement` },
				]
			}
			else{
				return [
					{
						value: 'follow-normal-schedule',
						text: 'The parties will follow their normal Parenting Time Schedule',
					},
					{
						value: 'follow-flexible-schedule',
						text: 'The parties will take a flexible approach about holidays generally',
					},
					{ value: 'follow-own-schedule', text: `I'll attach my own Holiday Schedule` },
				]
			}
			
		}
	},
	methods: {
		tableName(arrangement) {
			return arrangement == 'Primary' || arrangement == 'Shared'
				? 'Parenting Time Schedule'
				: 'Contact Time Schedule';
		},
		getHolidayDecisionSpecifyChange(type,value){
			let decisions = [];
			if(type == 'holiday' || type == 'specialDays')
			decisions.push({
						text: 'Adjust parenting time transition to include the day for parent with weekend parenting time',
						value: 'adjust-parenting-time',
					});
			if(value == 'summerBreak')
			decisions.push({
						text: 'Alternate weeks',
						value: 'alternate-weeks',
			
					});
			decisions.push(
					{
						text: 'Alternate years',
						value: 'alternate-years',
					},
					{
						text: 'Every year is the same',
						value: 'every-year-is-same',
					},
					{
						text: (type == 'holiday' || type == 'specialDays') ? 'Split day equally' : 'Split time equally',
						value:(type == 'holiday' || type == 'specialDays') ? 'split-day-equally' : 'split-time-equally',
					},
					{
						text: 'Other',
						value: 'other',
					})
			return decisions;
		},
		getHolidayTransitionTimesAgreedBetweenParties(holiday){
			let result = [{text:'With transition times as agreed between the parties',value: 'transition-times-agreed'}];
			if(this.agreementData.parenting && holiday){
				if(holiday.value == 'christmasEve')
					result.push({text: 'from 12:00 pm on December 24th to 12:00 p.m. on December 25th',value: 'has-christmas-eve'})
				if(holiday.value == 'christmasDay')
					result.push({text: 'from 12:00 pm on December 25th to 12:00 p.m. on December 26th',value: 'has-christmas-day'})
				result.push({text: 'Other',value: 'other'})
			}
			return result;
		},
		getParentingUpdated() {
			const holidayMap = holidays(this.agreementData);
			if (this.agreementData.parenting) {
				if (!this.agreementData.parenting.additionalHolidays)
					this.agreementData.parenting.additionalHolidays = [];

				if (!this.agreementData.parenting.hasOwnProperty('holidayAndSpecialDaysList')) {
					this.$set(this.agreementData.parenting, 'holidayAndSpecialDaysList', []);
				}

				// function to filter out new holidays(children's birthday) and add it to holidayAndSpecialDaysList
				const newHolidaysAndSpecialDays = holidayMap.filter(({ type, key, custom }) => (type === 'holiday' || type === 'specialDays' && !custom) &&
					!this.agreementData.parenting.holidayAndSpecialDaysList.some(existingHoliday => existingHoliday.key === key));

				let customHolidayAndSpecialDaysCount = this.agreementData.parenting.holidayAndSpecialDaysList.filter(hol => hol.custom == true);

				if (customHolidayAndSpecialDaysCount) {
					customHolidayAndSpecialDaysCount.forEach(filtered => this.agreementData.parenting.holidayAndSpecialDaysList.splice(this.agreementData.parenting.holidayAndSpecialDaysList.findIndex(hol => hol === filtered.custom == true), 1));
				}

				this.agreementData.parenting.holidayAndSpecialDaysList = [...this.agreementData.parenting.holidayAndSpecialDaysList, ...newHolidaysAndSpecialDays, ...this.agreementData.parenting.additionalHolidays]

				// loop for updating the label and other fields of partners and children when they change
				const filteredholidays = holidayMap.filter(({ type }) => type === 'holiday' || type === 'specialDays')
				for (let i = 0; i < this.agreementData.parenting.holidayAndSpecialDaysList.length; i++) {
					for (let j = 0; j < filteredholidays.length; j++) {
						if (
							this.agreementData.parenting.holidayAndSpecialDaysList[i].decision === 'partner1BirthdayDecision' &&
							filteredholidays[j].decision === 'partner1BirthdayDecision'
						) {
							this.agreementData.parenting.holidayAndSpecialDaysList[i].label = filteredholidays[j].label;
						}
						if (
							this.agreementData.parenting.holidayAndSpecialDaysList[i].decision === 'partner2BirthdayDecision' &&
							filteredholidays[j].decision === 'partner2BirthdayDecision'
						) {
							this.agreementData.parenting.holidayAndSpecialDaysList[i].label = filteredholidays[j].label;
						}
						this.agreementData.children.forEach((child) => {
							if (
								this.agreementData.parenting.holidayAndSpecialDaysList[i].key === child.key &&
								filteredholidays[j].key === child.key
							) {
								this.agreementData.parenting.holidayAndSpecialDaysList[i].decision = filteredholidays[j].decision;
								this.agreementData.parenting.holidayAndSpecialDaysList[i].label = filteredholidays[j].label;
								this.agreementData.parenting.holidayAndSpecialDaysList[i].text = filteredholidays[j].text;
								this.agreementData.parenting.holidayAndSpecialDaysList[i].value = filteredholidays[j].value;
								this.agreementData.parenting.holidayAndSpecialDaysList[i].id = filteredholidays[j].id
							}
						})

					}
				} 



				if (!this.agreementData.parenting.schoolBreakList)
					this.agreementData.parenting.schoolBreakList = holidayMap.filter(({ type }) => type === 'schoolBreak');
				// if(!this.agreementData.parenting.specialDaysList)
				// 	this.agreementData.parenting.specialDaysList = holidayMap.filter(({ type }) => type === 'specialDays');
				if (this.agreementData.parenting.otherSchoolBreakValue == (undefined || null))
					this.agreementData.parenting.otherSchoolBreakValue = 'Other'
			}
			return this.agreementData.parenting = this.agreementData.parenting.hasOwnProperty('additionalClausesToRecitals')
				? this.agreementData.parenting
				: { ...this.agreementData.parenting, additionalClausesToRecitals: [] };
			// 	: {...this.agreementData.parenting, additionalClausesToRecitals: []};
		},
		saveAdditionalHolidays(input){
			this.showAddHoliday = false

			if(!input){
				return
			}

				const additionalHoliday = {
					label: input,
					value: `${input}${this.agreementData.parenting.additionalHolidays.length}`,
					text: `${input}Text`,
					type: 'specialDays',
					decision:`${input}Decition${this.agreementData.parenting.additionalHolidays.length}`,
					custom: true,
					holidayDecisionSpecifyChange: '',
					partyWithParentingTimeOnOddYears: '',
					partyWithParentingTimeEveryYear: '',
					decisionSpecifyChangeOtherValue: '',
					holidayTransitionTimesAgreedBetweenParties: '',
					holidayTransitionTimesAgreedBetweenPartiesOtherValue: '',
					id: `additionalHoliday${this.agreementData.parenting.additionalHolidays.length}`
				}

			this.agreementData.parenting.additionalHolidays = [...this.agreementData.parenting.additionalHolidays, additionalHoliday]
			this.additionalHolidaysInput = "";	
		},
		deleteHoliday(value){
            this.parenting.additionalHolidays = this.parenting.additionalHolidays.filter((holiday)=> holiday.value !== value)
		}
	},
	watch: {
      'agreementData.agreement.updatedProvinceOfAgreement': function() {
		const holidayMap = holidays(this.agreementData);
		this.$set(this.agreementData.parenting, 'holidayAndSpecialDaysList', holidayMap.filter(({ type, custom }) => (type === 'holiday' || type === 'specialDays' && !custom)));
      },
    },
};
</script>

<style>
 .add-button{
	margin-top: 8px;
 }

 .add-holiday{
	gap: 10px;
	margin-top: 8px;
 }
 .holiday-checkbox{
	align-items: center !important;
	gap: 30px;
 }
 .delete-btn{
	color: grey;
	cursor: pointer;
	font-size: 20px ;
 }

</style>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Parenting Time</h4>

		<radio-group
			v-model="parenting.arrangement"
			class="mb-3"
			input-cols="8"
			:label="dynamicQuestions.arrangement"
			reset-undefined
			select
			:options="[
				{
					value: 'Primary',
					text: 'Primary with Parenting Time for other parent',
				},
				{
					value: 'Primary/Contact',
					text: 'Primary with Contact for other party',
				},
				{ value: 'Shared', text: 'Shared Parenting' },
			]"
			:testid="'parenting-time-arrangement'"
			:length="'long'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="
				parenting.arrangement === 'Primary' ||
				parenting.arrangement === 'Primary/Contact'
			"
		>
			<radio-group
				v-model="parenting.primaryParent"
				:agreement-data="agreementData"
				input-cols="4"
				label="Who is the primary parent?"
				partner
				reset-undefined
				select
				:testid="'parenting-time-primary-parent'"
				:length="'long'"
				:disabled="isViewMode"

			/>
		</conditional-group>

		<radio-group
			v-model="parenting.timeSchedule"
			class="mb-3"
			input-cols="10"
			label="How do you want to define the default parenting schedule?"
			reset-undefined
			select
			:options="[
				{
					value: 'schedule',
					text: `Incorporate the schedule laid out in the Parenting Time Planner`,
				},
				{
					value: 'flexible',
					text: 'Flexible and unspecified',
				},
			]"
			:testid="'parenting-time-parenting-schedule'"
			:length="'long'"
			:disabled="isViewMode"
		/>

		<div
			v-if="
				parenting.timeSchedule === 'schedule'
			"
		>

		<yes-no-choice
			v-model="parenting.suspendParentingTimeDuringHolidays"
			class="my-3"
			label="Would you like to specify particular parenting time during holidays, special days and school breaks within Divii?"
			reset-undefined
			select
			:testid="'parenting-time-particular-parenting-time'"
			:disabled="isViewMode"
		/>

		<!-- <div
			v-if="
				!parenting.suspendParentingTimeDuringHolidays &&
				parenting.timeSchedule === 'schedule'
			"
			class="m-1-0"
		>
			<strong>
				The Parenting Time Schedule will not be suspended to accommodate
				alternative time during holidays, special days, or school
				breaks.
			</strong>
		</div> -->

		<div v-if="!parenting.suspendParentingTimeDuringHolidays">
			<radio-group
				v-model="parenting.whatToDoWithHolidaysAndSpecialDays"
				class="mb-3"
				input-cols="8"
				label="What would you like to say about holidays, special days and school breaks?"
				reset-undefined
				select
				:options="whatToDoWithHolidaysAndSpecialDaysOptions"
				:testid="'what-to-do-with-holidays-and-special-days'"
				:length="'long'"
				:disabled="isViewMode"
			/>
		</div>

		<div 
			v-if="
				parenting.suspendParentingTimeDuringHolidays &&
				parenting.timeSchedule === 'schedule'
			"
		>			
			<p>
				Please select any holidays or special days for which you would
				like to specify a particular change:
			</p>
			

			<b-form-checkbox v-model="parenting.holidays" value="holiday" data-testid="parenting-time-holiday" id="parenting-time-holiday"
				:disabled="isViewMode"
				>Holidays and Special Days</b-form-checkbox
			>

			<div v-if="parenting.holidays" class="ml-4 my-2">
				<div v-for="(holiday, index) in holidayAndSpecialDayFields" :key="holiday.value">
					<div class="d-flex holiday-checkbox">
						<b-form-checkbox v-model="parenting[holiday.value]" :data-testid="'parenting-time-holiday-fields-'+index" :id="'parenting-time-holiday-fields-'+index"
						:disabled="isViewMode">{{
						holiday.label
						}}</b-form-checkbox> 
						<p class="delete-btn mb-0" 
						v-if="holiday.custom === true"
						@click="deleteHoliday(holiday.value)">×</p>
					</div>
					

					<conditional-group
						:condition="parenting[holiday.value]"
						:testid="'parenting-holiday-value-'+index+'-'"
						no-border
					>
						<radio-group
							v-model="parenting[holiday.decision]"
							select
							class="ml-4 my-2"
							:options="holidayDecision"
							input-cols="6"
							reset-undefined
							:testid="'parenting-time-holiday-decision-'+index"
							:length="'long'"
							:disabled="isViewMode"
						></radio-group>

						<div
							v-if="
								parenting[holiday.decision] === 'specify-change'
							"
							class="m-l-2"
						>
								<conditional-group
									class="my-3"
									:testid="'holiday-specify-change-'+index+'-'"
									:condition="parenting[holiday.decision] === 'specify-change' && (holiday.type =='holiday' || holiday.type =='specialDays') "
								>
									<radio-group
										v-model="parenting.holidayAndSpecialDaysList[index].holidayDecisionSpecifyChange"
										select
										class="mt-3"
										reset-undefined
										input-cols="8"
										label=""
										:options="getHolidayDecisionSpecifyChange(holiday.type,holiday.value)"
										:testid="'holiday-decision-specify-change-'+index"
										:length="'long'"
										:disabled="isViewMode"
									/>
									<conditional-group
										class="my-3"
										:testid="'parenting-time-on-odd-year-'+index+'-'"
										:condition="parenting.holidayAndSpecialDaysList[index].holidayDecisionSpecifyChange==='alternate-years'"
									>
										<radio-group
											v-model="parenting.holidayAndSpecialDaysList[index].partyWithParentingTimeOnOddYears"
											:agreement-data="agreementData"
											class="mb-3 col-12 col-sm-8"
											reset-undefined
											label="Which party will have parenting time on odd years?"
											select
											partner
											:testid="'party-having-parenting-time-on-odd-year-'+index"
											:length="'long'"
											:disabled="isViewMode"
										/>
									</conditional-group>
									<conditional-group
										class="my-3"
										:testid="'parenting-time-on-every-year-'+index+'-'"
										:condition="parenting.holidayAndSpecialDaysList[index].holidayDecisionSpecifyChange==='every-year-is-same'"
									>
										<radio-group
											v-model="parenting.holidayAndSpecialDaysList[index].partyWithParentingTimeEveryYear"
											:agreement-data="agreementData"
											class="mb-3 col-12 col-sm-8"
											reset-undefined
											label="What party will have parenting time every year?"
											select
											partner
											:testid="'party-have-parenting-time-every-year-'+index"
											:length="'long'"
											:disabled="isViewMode"
										/>
									</conditional-group>
									<conditional-group
										class="my-3"
										:testid="'holiday-decision-specify-other-'+index+'-'"
										:condition="parenting.holidayAndSpecialDaysList[index].holidayDecisionSpecifyChange==='other'"
									>
											<!-- v-model="parenting.holidayDecisionSpecifyChangeOtherValue" -->
										<!-- <input-group
											v-model="parenting.holidayAndSpecialDaysList[index].decisionSpecifyChangeOtherValue"
											input-cols="4"
											reset-undefined
											placeholder="Specify what the parties will do during this time (write as it should be included in Agreement)"
											:testid="'holiday-decision-specify-change-other-value'"
										/> -->
										<b-form-textarea
											v-model="parenting.holidayAndSpecialDaysList[index].decisionSpecifyChangeOtherValue"
											class="ml-2 my-3 col-12 col-sm-8 custom-scroll scroll-small text-area-scroll"
											placeholder="Specify what the parties will do during this time (write as it should be included in Agreement)"
											input-cols="6"
											:data-testid="'holiday-decision-specify-change-other-value-'+index"
											:disabled="isViewMode">
										</b-form-textarea>
									</conditional-group>
								</conditional-group>

								<p>Specify the transition time</p>
								<conditional-group
									class="my-3"
									:testid="'holiday-transition-times-'+index+'-'"
									:condition="parenting[holiday.decision] === 'specify-change' && (holiday.type =='holiday' || holiday.type =='specialDays')"
								>
									<radio-group
										v-model="parenting.holidayAndSpecialDaysList[index].holidayTransitionTimesAgreedBetweenParties"
										select
										class="mt-3"
										reset-undefined
										input-cols="8"
										label=""
										:options="getHolidayTransitionTimesAgreedBetweenParties(holiday)"
										:testid="'holiday-transition-times-agreed-between-parties-'+index"
										:length="'long'"
										:disabled="isViewMode"
									/>
									<conditional-group
										class="my-3"
										:testid="'holiday-transition-times-other-'+index+'-'"
										:condition="parenting.holidayAndSpecialDaysList[index].holidayTransitionTimesAgreedBetweenParties==='other'"
									>
										<!-- <input-group
											v-model="parenting.holidayAndSpecialDaysList[index].holidayTransitionTimesAgreedBetweenPartiesOtherValue"
											input-cols="4"
											reset-undefined
											placeholder="Notes about transition times and locations"
											:testid="'holiday-transition-times-agreed-between-parties-other-value'"
										/> -->
										<b-form-textarea
											v-model="parenting.holidayAndSpecialDaysList[index].holidayTransitionTimesAgreedBetweenPartiesOtherValue"
											class="ml-2 my-3 col-8 custom-scroll scroll-small text-area-scroll"
											placeholder="Notes about transition times and locations"
											size="ml"
											input-cols="6"
											no-size
											:data-testid="'holiday-transition-times-agreed-between-parties-other-value-'+index"
											:disabled="isViewMode"
										>
										</b-form-textarea>
										
									</conditional-group>
								</conditional-group>

							<!-- <b-col lg="8" class="row">
								<b-form-textarea
									v-model="parenting[holiday.text]"
									class="ml-2 my-3"
									placeholder="What will parenting look like during this time? (Please write this exactly as you would like to see it in the Agreement)"
									size="ml"
									input-cols="6"
									no-size
									:data-testid="'parenting-time-parenting-look-like-time-'+index"
								>
								</b-form-textarea>
							</b-col> -->
						</div>
					</conditional-group>
				</div>

				<b-button
					size="sm"
					class="add-button"
					variant="primary"
					@click="showAddHoliday = true"
					v-if="!showAddHoliday"
					data-testid="add-additional-holiday"
					:disabled="isViewMode"
				>
					Add Day
				</b-button>
                <div class="d-flex add-holiday" v-if="showAddHoliday">
					<b-input
					size="sm"
					placeholder="Enter Holiday"
					class="col-8 col-sm-3"
					v-model="additionalHolidaysInput"
					data-testid="additional-holiday"
					:disabled="isViewMode"
					>
			        </b-input>
				<b-button
					size="sm"
					variant="primary"
					@click="saveAdditionalHolidays(additionalHolidaysInput)"
					data-testid="save-additional-holiday"
					:disabled="additionalHolidaysInput? false : true || isViewMode"
				>
					Save
				</b-button>
				</div>
			</div>

			<b-form-checkbox
				v-model="parenting.schoolBreaks"
				value="school-breaks"
				data-testid="parenting-time-school-breaks"
				id="parenting-time-school-breaks"
				:disabled="isViewMode"
				>School Breaks</b-form-checkbox
			>
			<div v-if="parenting.schoolBreaks" class="ml-4 my-2">
				<div
					v-for="(schoolBreak, index) in schoolBreakFields"
					:key="schoolBreak.value"
				>
				<div class="d-flex holiday-checkbox">
					<b-form-checkbox v-model="parenting[schoolBreak.value]" :data-testid="'parenting-time-school-break-fields-'+index" :id="'parenting-time-school-break-fields-'+index"
					:disabled="isViewMode">
						<b-input
							size="sm"
							class="col-12"
							v-if="schoolBreak.value === 'other'"
							v-model="parenting.otherSchoolBreakValue"
							:data-testid="'other-schoolbreak-value-'+index"
							:disabled="isViewMode"
						>
						</b-input> 
						<span v-else>{{ schoolBreak.label }}</span>
					</b-form-checkbox>
				</div>
					<conditional-group
						:condition="parenting[schoolBreak.value]"
						no-border
					>
						<radio-group
							v-model="parenting[schoolBreak.decision]"
							select
							class="ml-4 my-2"
							:options="holidayDecision"
							input-cols="6"
							reset-undefined
							:testid="'parenting-time-school-break-decision-'+index"
							:length="'long'"
							:disabled="isViewMode"
						></radio-group>
						<div v-if="parenting[schoolBreak.decision] ==='specify-change'" class="m-l-2">
						<conditional-group
									class="my-3"
									:testid="'schoolBreak-specify-change-'+index+'-'"
									:condition="parenting[schoolBreak.decision] === 'specify-change' && (schoolBreak.type =='schoolBreak') "
								>
									<radio-group
										v-model="parenting.schoolBreakList[index].holidayDecisionSpecifyChange"
										select
										class="mt-3"
										reset-undefined
										input-cols="8"
										label=""
										:options="getHolidayDecisionSpecifyChange(schoolBreak.type,schoolBreak.value)"
										:testid="'schoolBreak-decision-specify-change-'+index"
										:length="'long'"
										:disabled="isViewMode"
									/>
									<conditional-group
										class="my-3"
										:testid="'parenting-time-on-odd-year-'+index+'-'"
										:condition="parenting.schoolBreakList[index].holidayDecisionSpecifyChange==='alternate-years'"
									>
										<radio-group
											v-model="parenting.schoolBreakList[index].partyWithParentingTimeOnOddYears"
											:agreement-data="agreementData"
											class="mb-3 col-12 col-sm-8"
											reset-undefined
											label="Which party will have parenting time on odd years?"
											select
											partner
											:testid="'party-having-parenting-time-on-odd-year-'+index"
											:length="'long'"
											:disabled="isViewMode"
										/>
									</conditional-group>
									<conditional-group
										class="my-3"
										:testid="'parenting-time-on-every-year-'+index+'-'"
										:condition="parenting.schoolBreakList[index].holidayDecisionSpecifyChange==='every-year-is-same'"
									>
										<radio-group
											v-model="parenting.schoolBreakList[index].partyWithParentingTimeEveryYear"
											:agreement-data="agreementData"
											class="mb-3 col-12 col-sm-8"
											reset-undefined
											label="What party will have parenting time every year?"
											select
											partner
											:testid="'party-have-parenting-time-every-year-'+index"
											:length="'long'"
											:disabled="isViewMode"
										/>
									</conditional-group>
									<conditional-group
										class="my-3"
										:testid="'schoolBreak-decision-specify-other-'+index+'-'"
										:condition="parenting.schoolBreakList[index].holidayDecisionSpecifyChange==='other'"
									>
										<!-- <input-group
											v-model="parenting.schoolBreakList[index].decisionSpecifyChangeOtherValue"
											input-cols="4"
											reset-undefined
											placeholder="Specify what the parties will do during this time (write as it should be included in Agreement)"
											:testid="'schoolBreak-decision-specify-change-other-value'"
										/> -->
										<b-form-textarea
											v-model="parenting.schoolBreakList[index].decisionSpecifyChangeOtherValue"
											class="ml-2 my-3 col-8 custom-scroll scroll-small text-area-scroll"
											placeholder="Specify what the parties will do during this time (write as it should be included in Agreement)"
											input-cols="6"
											:data-testid="'schoolBreak-decision-specify-change-other-value-'+index"
											:disabled="isViewMode">
										</b-form-textarea>
									</conditional-group>
								</conditional-group>

								<p>Specify the transition time</p>
								<conditional-group
									class="my-3"
									:testid="'schoolBreak-transition-times-'+index+'-'"
									:condition="parenting[schoolBreak.decision] === 'specify-change' && schoolBreak.type =='schoolBreak'"
								>
									<radio-group
										v-model="parenting.schoolBreakList[index].holidayTransitionTimesAgreedBetweenParties"
										select
										class="mt-3"
										reset-undefined
										input-cols="8"
										label=""
										:options="getHolidayTransitionTimesAgreedBetweenParties(schoolBreak)"
										:testid="'schoolBreak-transition-times-agreed-between-parties-'+index"
										:length="'long'"
										:disabled="isViewMode"
									/>
									<conditional-group
										class="my-3"
										:testid="'schoolBreak-transition-times-other-'+index+'-'"
										:condition="parenting.schoolBreakList[index].holidayTransitionTimesAgreedBetweenParties==='other'"
									>
										<!-- <input-group
											v-model="parenting.schoolBreakList[index].holidayTransitionTimesAgreedBetweenPartiesOtherValue"
											input-cols="4"
											reset-undefined
											placeholder="Notes about transition times and locations"
											:testid="'schoolBreak-transition-times-agreed-between-parties-other-value'"
										/> -->
										<b-form-textarea
											v-model="parenting.schoolBreakList[index].holidayTransitionTimesAgreedBetweenPartiesOtherValue"
											class="ml-2 my-3 col-8 custom-scroll scroll-small text-area-scroll"
											placeholder="Notes about transition times and locations"
											size="ml"
											input-cols="6"
											no-size
											:data-testid="'schoolBreak-transition-times-agreed-between-parties-other-value-'+index"
											:disabled="isViewMode"
										>
										</b-form-textarea>
									</conditional-group>
								</conditional-group>
							<!-- <b-col lg="6">
								<b-form-textarea
									v-model="parenting[schoolBreak.text]"
									class="ml-2 my-3"
									placeholder="What will parenting look like during this time? (Please write this exactly as you would like to see it in the Agreement)"
									size="ml"
									input-cols="6"
									no-size
									:data-testid="'parenting-time-parenting-look-like-school-break'+index"
								>
								</b-form-textarea>
							</b-col> -->
						</div>
					</conditional-group>
				</div>
			</div>
			<!-- END OF SCHOOL BREAKS -->

			<!-- <b-form-checkbox
				v-model="parenting.specialDays"
				value="special-days"
				>Special Days
			</b-form-checkbox>

			<div v-if="parenting.specialDays" class="ml-4 my-2">
				<div
					v-for="(specialDay, index) in specialDayFields"
					:key="specialDay.label"
				>
					<b-form-checkbox v-model="parenting[specialDay.value]">
						{{ specialDay.label }}
					</b-form-checkbox>

					<conditional-group
						:condition="parenting[specialDay.value]"
						no-border
					>
						<radio-group
							v-model="parenting[specialDay.decision]"
							select
							class="ml-4 my-2"
							:options="holidayDecision"
							input-cols="6"
							reset-undefined
							:testid="'parenting-time-special-day-'+index"
						></radio-group>

						<div
							v-if="
								parenting[specialDay.decision] ===
								'specify-change'
							"
						>
							<b-col lg="6">
								<b-form-textarea
									v-model="parenting[specialDay.text]"
									class="ml-2 my-3"
									placeholder="What will parenting look like during this time? (Please write this exactly as you would like to see it in the Agreement)"
									size="ml"
									input-cols="6"
									no-size
									:data-testid="'parenting-time-parenting-look-like-special-day-'+index"
								>
								</b-form-textarea>
							</b-col>
						</div>
					</conditional-group>
				</div>
			</div> -->
		</div>
	</div>
	
		<yes-no-choice
			v-model="parenting.parentingTimeHolidayMeeting"
			class="mt-3"
			:label="dynamicQuestions.parentingTimeHolidayMeeting"
			reset-undefined
			:testid="'parenting-time-holiday-meeting-plans'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="parenting.parentingTimeHolidayMeeting === true"
		>
			<check-group
				v-model="parenting.parentingTimeHolidayMeetingMonth"
				select
				:label="dynamicQuestions.parentingTimeHolidayMeetingMonth"
				:options="monthOptions"
				@reset="parenting.parentingTimeHolidayMeetingMonth = []"
				:testid="'parenting-time-holiday-meeting-month'"
				:id="'parenting-time-holiday-meeting-month'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<yes-no-choice
			v-model="parenting.partner1Restriction"
			class="mb-3"
			reset-undefined
			:label="`Is there a restriction on who can be present during ${partner1Name}'s time?`"
			:testid="'parenting-time-p1-present-restriction'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="parenting.partner1Restriction"
		>
			<input-group
				v-model="parenting.partner1RestrictionWho"
				input-cols="4"
				label="Who may not be present?"
				reset-undefined
				:testid="'parenting-time-p1-restrict'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<yes-no-choice
			v-model="parenting.partner2Restriction"
			class="mb-3"
			reset-undefined
			:label="`Is there a restriction on who can be present during ${partner2Name}'s time?`"
			:testid="'parenting-time-p2-present-restriction'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="parenting.partner2Restriction"
		>
			<input-group
				v-model="parenting.partner2RestrictionWho"
				input-cols="4"
				label="Who may not be present?"
				reset-undefined
				:testid="'parenting-time-p2-restriction'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<yes-no-choice
			v-model="parenting.agreeChildcareProviders"
			class="mb-3"
			:label="dynamicQuestions.agreeChildcareProviders"
			reset-undefined
			:testid="'parenting-time-child-care-providers'"
			:disabled="isViewMode"
		/>

		<yes-no-choice
			v-model="parenting.firstRightOfRefusal"
			class="mb-3"
			:label="dynamicQuestions.firstRightOfRefusal"
			reset-undefined
			:testid="'parenting-time-refusal-child-care'"
			:disabled="isViewMode"
		/>

		<yes-no-choice
			v-model="parenting.timeDriving"
			class="mb-3"
			:label="dynamicQuestions.timeDriving"
			reset-undefined
			:testid="'parenting-time-driving-time-responsibility'"
			:disabled="isViewMode"
		/>

		<yes-no-choice
			v-model="parenting.supervision"
			class="mb-3"
			:label="dynamicQuestions.supervision"
			reset-undefined
			:testid="'parenting-time-supervision'"
			:disabled="isViewMode"
		/>

		<conditional-group class="my-3" :condition="parenting.supervision">
			<radio-group
				v-model="parenting.supervisionPartner"
				class="mb-3"
				:agreement-data="agreementData"
				input-cols="4"
				label="Which party requires supervision?"
				partner
				reset-undefined
				select
				:testid="'parenting-time-supervision-party'"
				:length="'long'"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="parenting.supervisionSupervisor"
				class="mb-3"
				input-cols="4"
				label="Supervisor name"
				reset-undefined
				:testid="'parenting-time-supervisor-name'"
				:disabled="isViewMode"
			/>

			<conditional-check-group
				v-model="parenting.supervisionConditions"
				class="mb-3"
				input-cols="10"
				label="What conditions will conclude the requirement for supervision?"
				reset-undefined
				select
				:options="[
					{value: 'After a specified period of time'},
					{value: 'After a specific number of supervised visits'},
					{value: 'After the parent completes a course'},
					{value: 'After attending counselling'},
					{value: 'Recommendation from a third party'},
					{value: 'Agreement'},
					{value: 'Order of the court'}
				]"
				:testid="'parenting-time-conditions-for-supervision'"
				:id="'parenting-time-conditions-for-supervision-'"
				:disabled="isViewMode"
			>
				<template slot="After a specified period of time">
					<date-input
						v-model="parenting.supervisionDate"
						label="On what date will supervision end?"
						reset-undefined
						:testid="'parenting-time-supervision-end-date'"
						:disabled="isViewMode"
					/>
				</template>

				<template slot="After a specific number of supervised visits">
					<input-group
						v-model="parenting.supervisionVisits"
						input-cols="2"
						label="Number of supervised visits required"
						number
						reset-undefined
						:testid="'parenting-time-supervisied-visits-count'"
						:disabled="isViewMode"
					/>
				</template>

				<template slot="After the parent completes a course">
					<radio-group
						v-model="parenting.supervisionCourse"
						input-cols="10"
						label="Course"
						reset-undefined
						select
						:options="[
							{
								text: 'An accredited parenting course',
								value: 'an accredited parenting course',
							},
							{
								text: 'An accredited parenting after separation course',
								value: 'an accredited parenting after separation course',
							},
							{
								text: 'A specified course',
								value: 'a specified course',
							},
						]"
						:testid="'parenting-time-course-completion'"
						:length="'long'"
						:disabled="isViewMode"
					/>

					<conditional-group
						class="mt-3"
						:condition="
							parenting.supervisionCourse === 'a specified course'
						"
					>
						<input-group
							v-model="parenting.supervisionCourseName"
							input-cols="4"
							label="A specified course"
							reset-undefined
							:testid="'parenting-time-specified-course'"
							:disabled="isViewMode"
						/>
					</conditional-group>
				</template>

				<template slot="After attending counselling">
					<yes-no-choice
						v-model="parenting.specifyCounsellorName"
						label="Would you like to specify the counsellor's name?"
						reset-undefined
						:testid="'parenting-time-counsellor-name-specification'"
						:disabled="isViewMode"
					/>
					<conditional-group
						class="mt-3"
						:condition="parenting.specifyCounsellorName"
					>
						<input-group
							v-model="parenting.supervisionCounsellorName"
							input-cols="4"
							label="Name of Counsellor"
							reset-undefined
							:testid="'parenting-time-counsellor-name'"
							:disabled="isViewMode"
						/>
					</conditional-group>

					<yes-no-choice
						v-model="parenting.specifyAmountOfCounselling"
						class="mt-3"
						label="Would you like to specify the amount of counselling that must be completed?"
						reset-undefined
						:testid="'parenting-time-counselling-amount-specification'"
						:disabled="isViewMode"
					/>
					<conditional-group
						class="mt-3"
						:condition="parenting.specifyAmountOfCounselling"
					>
						<radio-group
							v-model="parenting.specifyAmountOfCounsellingChoice"
							input-cols="10"
							label="Conditions"
							reset-undefined
							select
							:options="[
								'Must attend a specified number of counselling sessions',
								'Must attend counselling for a specified number of months',
							]"
							:testid="'parenting-time-amount-specification-options'"
							:length="'long'"
							:disabled="isViewMode"
						/>
						<conditional-group
							class="mt-3"
							:condition="
								parenting.specifyAmountOfCounsellingChoice ===
								'Must attend a specified number of counselling sessions'
							"
						>
							<input-group
								v-model="parenting.supervisionCounsellorVisits"
								input-cols="4"
								label="Number of counselling sessions"
								reset-undefined
								:testid="'parenting-time-counselling-session-count'"
								:disabled="isViewMode"
							/>
						</conditional-group>
						<conditional-group
							class="mt-3"
							:condition="
								parenting.specifyAmountOfCounsellingChoice ===
								'Must attend counselling for a specified number of months'
							"
						>
							<input-group
								v-model="parenting.supervisionCounsellorMonths"
								input-cols="4"
								label="Number of months"
								reset-undefined
								:testid="'parenting-time-month-count'"
								:disabled="isViewMode"
							/>
						</conditional-group>
					</conditional-group>
				</template>

				<!-- REPEAT SECTION -->
				<yes-no-choice
					v-model="parenting.partner1Restriction"
					class="mb-3"
					reset-undefined
					:label="`Is there a restriction on who can be present during ${partner1Name}'s time?`"
					:testid="'parenting-time-p1-present-restiction-time'"
					:disabled="isViewMode"
				/>
				<!-- END OF REPEAT SECTION -->

				<!-- REPEAT SECTION -->
				<conditional-group
					class="my-3"
					:condition="parenting.partner1Restriction"
				>
					<input-group
						v-model="parenting.partner1RestrictionWho"
						input-cols="4"
						label="Who may not be present?"
						reset-undefined
						:testid="'parenting-time-p1-restiction-counselling'"
						:disabled="isViewMode"
					/>
				</conditional-group>
				<!-- END OF REPEAT SECTION -->

				<!-- REPEAT SECTION -->
				<yes-no-choice
					v-model="parenting.partner2Restriction"
					class="mb-3"
					reset-undefined
					:label="`Is there a restriction on who can be present during ${partner2Name}'s time?`"
					:testid="'parenting-time-p2-present-restiction-time'"
					:disabled="isViewMode"
				/>
				<!-- END OF REPEAT SECTION -->

				<!-- REPEAT SECTION -->
				<conditional-group
					class="my-3"
					:condition="parenting.partner2Restriction"
				>
					<input-group
						v-model="parenting.partner2RestrictionWho"
						input-cols="4"
						label="Who may not be present?"
						reset-undefined
						:testid="'parenting-time-p2-restriction-counselling'"
						:disabled="isViewMode"
					/>
				</conditional-group>
				<!-- END OF REPEAT SECTION -->

				<!-- REPEAT SECTION -->
				<yes-no-choice
					v-model="parenting.agreeChildcareProviders"
					class="mb-3"
					:label="dynamicQuestions.agreeChildcareProviders"
					reset-undefined
					:testid="'parenting-time-same-child-care-providers'"
					:disabled="isViewMode"
				/>
				<!-- END OF REPEAT SECTION -->

				<!-- REPEAT SECTION -->
				<yes-no-choice
					v-model="parenting.firstRightOfRefusal"
					class="mb-3"
					:label="dynamicQuestions.firstRightOfRefusal"
					reset-undefined
					:testid="'parenting-time-refusal-child-care'"
					:disabled="isViewMode"
				/>
				<!-- END OF REPEAT SECTION -->

				<!-- REPEAT SECTION -->
				<yes-no-choice
					v-model="parenting.timeDriving"
					class="mb-3"
					:label="dynamicQuestions.timeDriving"
					reset-undefined
					:testid="'parenting-time-drive-time-responsibility'"
					:disabled="isViewMode"

				/>
				<!-- END OF REPEAT SECTION -->

				<!-- REPEAT SECTION -->
				<yes-no-choice
					v-model="parenting.supervision"
					class="mb-3"
					:label="dynamicQuestions.supervision"
					reset-undefined
					:testid="'parenting-time-supervision-either-party'"
					:disabled="isViewMode"
				/>
				<!-- END OF REPEAT SECTION -->

				<!-- REPEAT SECTION -->
				<conditional-group
					class="my-3"
					:condition="parenting.supervision"
				>
					<radio-group
						v-model="parenting.supervisionPartner"
						class="mb-3"
						:agreement-data="agreementData"
						input-cols="4"
						label="Which party requires supervision?"
						partner
						reset-undefined
						select
						:testid="'parenting-time-supervision-party'"
						:length="'long'"
						:disabled="isViewMode"
					/>
				</conditional-group>
				<!-- END OF REPEAT SECTION -->

				<!-- REPEAT SECTION -->
				<input-group
					v-model="parenting.supervisionSupervisor"
					class="mb-3"
					input-cols="4"
					label="Supervisor name"
					reset-undefined
					:testid="'parenting-time-supervisor-name'"
					:disabled="isViewMode"
				/>
				<!-- END OF REPEAT SECTION -->

				<!-- REPEAT SECTION -->

				<conditional-check-group
					v-model="parenting.supervisionConditions"
					class="mb-3"
					input-cols="10"
					label="What conditions will conclude the requirement for supervision?"
					reset-undefined
					select
					:options="[
						{value: 'After a specified period of time'},
						{value: 'After a specific number of supervised visits'},
						{value: 'After the parent completes a course'},
						{value: 'After attending counselling'},
						{value: 'Recommendation from a third party'},
						{value: 'Agreement'},
						{value: 'Order of the court'}
					]"
					:testid="'parenting-time-requirement-for-supervision-conditions'"
					:id="'parenting-time-requirement-for-supervision-conditions-'"
					:disabled="isViewMode"
				></conditional-check-group>
				<!-- END OF REPEATS -->

				<template slot="Recommendation from a third party">
					<input-group
						v-model="parenting.supervisionThirdPartyRecommendation"
						input-cols="4"
						label="Name of third party"
						reset-undefined
						:testid="'parenting-time-thirdparty-name'"
						:disabled="isViewMode"
				/></template>
			</conditional-check-group>
		</conditional-group>
		<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="parenting.additionalClausesToRecitals" :testid="'parenting-time-add-clause-to-recitals-'"
		:disabled="isViewMode"/>
		<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="parenting.additionalClauses" :testid="'parenting-time-add-clause-'"
		:disabled="isViewMode"/>
	</b-card>
</template>
<style scoped>
.m-1-0{
	margin:1rem 0;
}

.m-l-2{
	margin-left: 2rem;
}
</style>