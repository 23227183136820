<script>
import { mapState } from 'vuex';
import {  BIconDownload,BIconPeople ,BIconChevronLeft ,BIconChevronRight,BIconBook } from 'bootstrap-vue';
import permissions from '@/mixins/permissions';
import storeHelper from '@/mixins/storeHelper';
import Users from '../Users.vue';
import agreementTitle from '@/lib/agreement-title';
import {hasViewPermission, currentRoleLevelName} from '@/mixins/roleLevelPermissions';
import {trialActiveForClients,isClientUserAgreement} from '@/mixins/roleLevelPermissions';
import SubscriptionModal from '../SubscriptionModal.vue';

export default {
	components: {
		BIconDownload,
		BIconPeople,
		Users,
		BIconChevronLeft,
		BIconChevronRight,
		BIconBook,
		SubscriptionModal
	},
	// props: ['clientUserAgreement'],
	props: ['isEdPanelOpen','currentRoleLevelName','showPermissionDeniedPageDisplay','agreementData'],

	computed: {
		...mapState(['agreement', 'editedAgreement', 'saving']),
		canDownload() {
			return this.editedAgreement;
		},
		title() {
			const title =
				this.agreement && agreementTitle(this.agreement).co != 'and'
					? agreementTitle(this.agreement)
					: '';
			return title;
		},
		clientUserAgreement(){
			return this.currentRoleLevelName == 'client-sharing-user' ? true : false;
		},
		isClientUserAgreementComputed(){
			return isClientUserAgreement();
		},
	},
	data() {
		return {
			usersVisible: false,
			activeTab:'builder',
			showSubscriptionModal: false
		};
	},
	mixins: [permissions,storeHelper],
	methods: {
		hasViewPermission,
		showUsers() {
			if(trialActiveForClients()){
				this.showSubscriptionModal = true
				return
			}
			else{
				if(this.isEdPanelOpen){
					this.toggleEdPanel()
				}
				this.usersVisible = true;
			}
		},

		download(pageToDownload) {
			let routeUrl = `${window.location.origin}${window.location.pathname}/preview`
			if(pageToDownload == 'property-schedule')
			{let assetView=localStorage.getItem('isAssetView')
			console.log("assetView:", assetView)
			routeUrl += `?download=${pageToDownload}`}
			window.open(routeUrl);
		},
		toggleEdPanel(){
			if(trialActiveForClients()){
				this.showSubscriptionModal = true
				return
			}
			else{
				this.$emit('toggleEdPanel')
				if(this.usersVisible){
					this.usersVisible = false;
				}
			}
			
		},
		trialActiveForClients
	},
	mounted() {
		this.activeTab = this.getSessionStorageString('activeTab');
		window.addEventListener('session-storage-changed', (event) => {
			if(event.detail && event.detail.activeTab)
			this.activeTab = event.detail.activeTab;
		});

		setTimeout(() => {
			if(document.getElementById('users-sidebar') && document.getElementById('users-sidebar').children && document.getElementById('users-sidebar').children[0] && document.getElementById('users-sidebar').children[0].children  && document.getElementById('users-sidebar').children[0].children)
					document.getElementById('users-sidebar').children[0].children[0].setAttribute('data-test-id','close-share-agreement')
		}, 1000);
		
	},
};
</script>

<style>
.users-sidebar {
	top: 70px;
	overflow-y: scroll;
	max-height: calc(100vh - 70px);
	width: 400px;
}

.download-agr-btn{
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

@media only screen and (max-width: 480px) {
	.hide-mobile {
		display: none;
	}

	.title {
		/* max-width: 296px; */
		max-width: 140px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
@media only screen and (min-width: 480px) and (max-width: 770px) {
	.hide-mobile {
		display: none;
	}

	.title {
		max-width: 270px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.saving-btn{
	border: 1px solid #243B42;
    /* color: #4384a0; */
}

.b-sidebar-header{
	direction: rtl;
}

.b-sidebar.b-sidebar-right > .b-sidebar-header .close{
	margin-left: auto;
    margin-right: 0;
}

.share-btn{
	background-color: #678B95;
	border-color: #678B95;
}
</style>

<template>
	<div class='print-hide'>
		<b-row class="mt-1" style="height: 32px">
			<b-col cols="auto">
				<h5 class="title">{{ title }}</h5>
			</b-col>
			<b-col>
				<div class="float-right">
					<b-badge
						pill
						variant="light"
						class="p-2 mx-2 saving-btn"
						style="width: 64px"
						>{{ saving ? 'Saving...' : 'Saved' }}</b-badge
					>
					<!-- <b-button-group> -->
						<b-button
							@click="download(activeTab)"
							size="sm"
							variant="dark-blue"
							data-testid="download-property-schedule"
							v-if="activeTab == 'property-schedule'"
						>
							<b-icon-download />
							<span class="hide-mobile"> Download</span>
						</b-button>
						<b-button
							v-if="canDownload && !clientUserAgreement && !trialActiveForClients()"
							@click="download"
							size="sm"
							variant="dark-blue"
							data-testid="download-agreement"
							class="download-agr-btn"
						>
							<b-icon-download />
							<span class="hide-mobile"> Download Agreement</span>
						</b-button>
						<b-button
							v-if="!clientUserAgreement && hasViewPermission('share-agreement') && agreementData"
							@click="showUsers"
							size="sm"
							variant="dark-blue"
							data-testid="share-agreement"
							class="ml-2 share-btn"
							:class="{'btn-disabled-but-clickable': trialActiveForClients()}"
						>
							<b-icon-people />
							<span class="hide-mobile"> Share</span>
						</b-button>
						<b-button 
							size="sm"
							variant="primary"
							class="ml-2"
							@click="toggleEdPanel"
							v-if="(currentRoleLevelName === 'client-level-1' ||currentRoleLevelName === 'client-level-2')&& !showPermissionDeniedPageDisplay && !isClientUserAgreementComputed && agreementData"
							data-testid="toggle-education-panel"
							:class="{'btn-disabled-but-clickable': trialActiveForClients()}"
						>
							<b-icon-chevron-right v-if="isEdPanelOpen"></b-icon-chevron-right>
							<b-icon-chevron-left v-else></b-icon-chevron-left>
							Learn &nbsp;
							<b-icon-book/>
						</b-button>
					<!-- </b-button-group> -->
				</div>
			</b-col>
		</b-row>

		<b-sidebar
			v-if="agreement && !trialActiveForClients()"
			id="users-sidebar"
			v-model="usersVisible"
			title="Users"
			right
			shadow
			sidebar-class="users-sidebar"
		>
			<users :id="agreement.id" />
		</b-sidebar>

		<subscription-modal :showSubscriptionModal="showSubscriptionModal" @close="showSubscriptionModal = false"></subscription-modal>

	</div>
</template>
