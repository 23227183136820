<script>
import VueCal from 'vue-cal';
// import 'vue-cal/dist/drag-and-drop.es.js';
import 'vue-cal/dist/drag-and-drop.js';
import 'vue-cal/dist/vuecal.css';
import shortid from 'shortid';
import addMinutes from 'date-fns/addMinutes';

import CalendarTitle from './Calendar/CalendarTitle.vue';
import EditEvent from './Calendar/EditEvent.vue';
import isBefore from 'date-fns/isBefore/index';
import isWithinInterval from 'date-fns/isWithinInterval/index';
import { trialActiveForClients } from '@/mixins/roleLevelPermissions';
import SubscriptionModal from '../components/SubscriptionModal.vue';

function Event({ event, originalEvent, selectedPartnerDetails = 'partner1' }) {
	return {
		...event,
		end: addMinutes(event.start, 60),
		class: selectedPartnerDetails,
		id:
			originalEvent && originalEvent.id
				? originalEvent.id
				: shortid.generate(),
	};
}

export default {
	name: 'Calendar',
	components: {
		VueCal,
		CalendarTitle,
		EditEvent,
		SubscriptionModal
	},
	props: [
		'agreementData',
		'events',
		'updateEventChange',
		'deleteEvent',
		'selectedPartnerDetails',
		'partner1Name',
		'partner2Name',
		'lastEvent',
		'firstEvent',
		'firstAndLastAreSame',
		'disabled'
	],
	data() {
		const selectedDate = '2022-01-02';
		return {
			selectedDate,
			selectedDate2: '2022-01-09',
			showEditModal: false,
			editingEvent: null,
			mobile: window.matchMedia('(max-width: 575px)').matches,
			showAddModal: false,
			editingNewEvent: null,
			showSubscriptionModal:false
		};
	},
	methods: {
		onEventCreate(event) {
			const updatedEvent = new Event({
				event,
				selectedPartnerDetails: this.selectedPartnerDetails,
			});
			this.updateEventChange(updatedEvent);

			return updatedEvent;
		},
		onEventChange({ event }) {
			const updatedEvent = { ...event };

			this.updateEventChange(updatedEvent);

			return updatedEvent;
		},
		formatTime(hours) {
			return `${hours == 12 || hours == 0 ? 12 : hours % 12}:00`;
		},
		editEvent(event) {
			if(trialActiveForClients()){
				this.showSubscriptionModal = true
				return
			}
			else{
				this.showEditModal = true;
				this.editingEvent = { ...event };
			}
		},
		hideModal() {
			this.showEditModal = false;
			this.editingEvent = null;
		},
		async saveEvent() {
			if (isBefore(this.editingEvent.end, this.editingEvent.start)) {
				let endDate = this.editingEvent.end;
				this.editingEvent.end = new Date(2022, 0, 15, 23, 59)
				const weekOneEvent = {
					start: new Date(2022, 0, 2, 0, 0),
					end: endDate,
					class: this.editingEvent.class,
					transition: this.editingEvent.transition,
					transitionDetails: this.editingEvent.transitionDetails,
					id: shortid.generate(),
					eventNo: this.editingEvent.eventNo,
				};
				await this.updateEventChange(this.editingEvent);
				await this.updateEventChange(weekOneEvent);
			} else {
				await this.updateEventChange(this.editingEvent);
			}
			this.showEditModal = false;
			this.editingEvent = null;
		},
		async saveFirstLastEvent(first, last) {
			await this.updateEventChange(first);
			await this.updateEventChange(last);
			this.showEditModal = false;
			this.editingEvent = null;
		},
		deleteEditingEvent() {
			this.deleteEvent(this.editingEvent);
			this.hideModal();
		},
		createEvent() {
			this.showEditModal = true;
			this.editingEvent = {};
		},
		cellDoubleClick(selectedTime) {
			if(trialActiveForClients()){
				this.showSubscriptionModal = true
				return
			}else{
				let isBetweenTimes = this.checkEventBetweenSelectedTimeSlot(selectedTime)
				if(!isBetweenTimes)
				{
					this.createNewEvent(selectedTime)
				}
			}

		},
		checkEventBetweenSelectedTimeSlot(selectedTime){
			return this.events.some((event)=>{
				return isWithinInterval(new Date(selectedTime),
				{
					start: new Date(event.start),
					end: new Date(event.end)
				});
			})
		},
		deleteNewEditingEvent() {
			this.hideNewModal();
		},
		createNewEvent(selectedTime) {
			this.editingNewEvent = {
				start: selectedTime ? new Date(selectedTime) : new Date(2022, 0, 2, 12, 0) ,
				end: selectedTime ? new Date(selectedTime.setHours(selectedTime.getHours() + 1)) : new Date(2022, 0, 2, 13, 0),
				id: shortid.generate(),
				class: 'partner1',
				eventNo: shortid.generate(),
			};
			this.showAddModal = true;
		},
		hideNewModal() {
			this.showAddModal = false;
		},
		saveEditingEvent() {
			// If the end is before the start that means it spans multiple weeks and we need one event per week
			if (isBefore(this.editingNewEvent.end, this.editingNewEvent.start)) {
				let uniqueId = shortid.generate()
				const weekTwoEvent = {
					start: this.editingNewEvent.start,
					end: new Date(2022, 0, 15, 23, 59),
					class: this.editingNewEvent.class,
					transition: this.editingNewEvent.transition,
					transitionDetails: this.editingNewEvent.transitionDetails,
					eventNo: uniqueId,
					id: shortid.generate(),
				};
				const weekOneEvent = {
					start: new Date(2022, 0, 2, 0, 0),
					end: this.editingNewEvent.end,
					class: this.editingNewEvent.class,
					transition: this.editingNewEvent.transition,
					transitionDetails: this.editingNewEvent.transitionDetails,
					eventNo: uniqueId,
					id: shortid.generate(),
				};
				this.$store.dispatch('updateEvent', weekTwoEvent).then(() => {
					this.$store.dispatch('updateEvent', weekOneEvent);
				});
			} else {
				this.updateEventChange(this.editingNewEvent);
			}
		},
		trialActiveForClients

	},
};
</script>

<style>
.vuecal {
	height: calc(100% - 37px);
}

.vuecal__event.partner1 {
	background-color: #9CB6BD;
	border: 0.5px solid #E7E8E9;
	color: #fff;
}
.vuecal__event.partner2 {
	background-color: #3F6772;
    border: 0.5px solid #E7E8E9;
    color: #fff;
}
.vuecal__event.partner3 {
	background-color: #6D757C;
	border: 1px solid #E7E8E9;
	color: #fff;
}

.event-middle .vuecal__event-time,
.event-end .vuecal__event-time {
	display: none;
}
.event-middle .vuecal__event-content,
.event-end .vuecal__event-content {
	display: none;
}
.event-middle .vuecal__event-title,
.event-end .vuecal__event-title {
	display: none;
}
.week-label {
    color: #3F6772;
    margin-top: -20px;
}
.p1-height, .p2-height{
	height: 100%;
}

@media screen {
	.vuecal-disabled{
		pointer-events: none;
		opacity: 0.7;
	}
}

@media print{
	.print-alignment{
		/* display: inline; */
		display: flex;
		flex-direction: column;
	}
	.print-width{
		max-width: 100%;
	}
	/* .print-margin-1{
		margin-top: 10px;
		margin-bottom: 0px !important;
		font-size: 20px;
	}
	.print-margin-2{
		margin-top: 20px;
		margin-bottom: 0px !important;
		font-size: 20px;
	} */
	.print-margin-2{
		margin-top: 30px;
		margin-bottom: 30px;
		font-size: 20px;
	}
	.print-margin-1{
		margin-top: 30px;
		margin-bottom: 30px;
		font-size: 20px;
	}
}

@media print and (min-resolution:.001dpcm)
	{ @supports (-webkit-appearance:none) and (stroke-color:transparent) { 
	.print-margin-1{
		margin-top: 10px !important;
		margin-bottom: 0px !important;
		font-size: 20px !important;
	}
	.print-margin-2{
		margin-top: 20px !important;
		margin-bottom: 0px !important;
		font-size: 20px !important;
	} 	
}
} 
</style>

<template>
	<b-row no-gutters class="print-alignment">
		<edit-event
			title="Edit Event"
			:show-edit-modal="showEditModal"
			:hide-modal="hideModal"
			:save-event="saveEvent"
			:save-first-last-event="saveFirstLastEvent"
			:editing-event="editingEvent"
			:partner1-name="partner1Name"
			:partner2-name="partner2Name"
			:delete-editing-event="deleteEditingEvent"
			:last-event="lastEvent"
			:first-event="firstEvent"
		/>
		<edit-event
				title="Add Event"
				:show-edit-modal="showAddModal"
				:hide-modal="hideNewModal"
				:save-event="saveEditingEvent"
				:editing-event="editingNewEvent"
				:partner1-name="partner1Name"
				:partner2-name="partner2Name"
				:delete-editing-event="deleteNewEditingEvent"
			/>
		<b-col cols="6" class="p1-height print-width week1">
			<h4 class="week-label print-margin-1">Week 1</h4>
			<vue-cal
				:time-cell-height="23"
				class="vuecal--blue-theme"
				hide-view-selector
				hide-title-bar
				:disable-views="['years', 'year', 'month', 'day']"
				:selected-date="selectedDate"
				:events="events"
				:snap-to-time="15"
				:drag-to-create-threshold="0"
				:editable-events="{ title: true, drag: true, resize: true, delete: false, create: false }"
				:overlaps-per-time-step="false"
				resize-x
				:on-event-dblclick="editEvent"
				@cell-dblclick="cellDoubleClick"
				@event-change="onEventChange"
				:startWeekOnSunday="true"
				:class="{'vuecal-disabled': disabled}"
			>
			<!-- :drag-to-create-event="false" -->
				<template #weekday-heading="param">
					{{
						mobile
							? param.heading.label.substring(0, 1)
							: param.heading.label.substring(0, 3)
					}}
				</template>
				<template #time-cell="{ hours, minutes }">
					<div
						:class="{
							'vuecal__time-cell-line': true,
							hours: !minutes,
						}"
					>
						<strong v-if="[8, 12, 16].includes(hours)">
							{{ formatTime(hours) }}
						</strong>
						<span v-else> {{ formatTime(hours) }}</span>
					</div>
				</template>
				<template #event="{ event }">
					<calendar-title
						:event="event"
						:partner1-name="partner1Name"
						:partner2-name="partner2Name"
						:first-and-last-are-same="firstAndLastAreSame"
						:first-event="firstEvent"
						:last-event="lastEvent"
					/>
				</template>
			</vue-cal>
		</b-col>
		<b-col cols="6" class="p2-height print-width">
			<h4 class="week-label print-margin-2">Week 2</h4>
			<vue-cal
				ref="vuecal2"
				:time-cell-height="23"
				class="vuecal--blue-theme"
				hide-view-selector
				hide-title-bar
				:disable-views="['years', 'year', 'month', 'day']"
				:selected-date="selectedDate2"
				:events="events"
				:on-event-dblclick="editEvent"
				@cell-dblclick="cellDoubleClick"
				:drag-to-create-threshold="0"
				:editable-events="{ title: true, drag: true, resize: true, delete: false, create: false }"
				@event-change="onEventChange"
				:startWeekOnSunday="true"
				:class="{'vuecal-disabled': disabled}"
			>
				<template #weekday-heading="param">
					{{ param.heading.label.substring(0, 3) }}
				</template>
				<template #time-cell="{ hours, minutes }">
					<div
						:class="{
							'vuecal__time-cell-line': true,
							hours: !minutes,
						}"
					>
						<strong v-if="[8, 12, 16].includes(hours)">
							{{ formatTime(hours) }}
						</strong>
						<span v-else> {{ formatTime(hours) }}</span>
					</div>
				</template>
				<template #event="{ event }">
					<calendar-title
						:event="event"
						:partner1-name="partner1Name"
						:partner2-name="partner2Name"
						:first-and-last-are-same="firstAndLastAreSame"
						:first-event="firstEvent"
						:last-event="lastEvent"
					/>
				</template>
			</vue-cal>
		</b-col>
		<subscription-modal :showSubscriptionModal="showSubscriptionModal" @close="showSubscriptionModal = false"></subscription-modal>
	</b-row>
</template>
